import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const AdContainer = styled.div`
  width: 100%;
  min-height: 280px;
  margin: 10px 0;
`;

const DisplayAds = () => {
  const advertRef = useRef(null);

  useEffect(() => {
    const pushAd = () => {
      try {
        console.log("Trying to push ad");
        if (advertRef.current) {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        console.log("Ad push successful");
      } catch (e) {
        console.error("Ad push failed:", e);
      }
    };

    // 광고 초기화가 완료된 후에 push
    if (window.adsbygoogle) {
      pushAd();
    } else {
      // AdSense 스크립트 로드 완료 대기
      const script = document.querySelector('script[src*="adsbygoogle"]');
      if (script) {
        script.onload = pushAd;
      }
    }

    return () => {
      // cleanup
      if (advertRef.current) {
        advertRef.current.remove();
      }
    };
  }, []);

  return (
    <AdContainer>
      <ins
        ref={advertRef}
        className="adsbygoogle"
        style={{
          display: "block",
          width: "100%",
          height: "280px",
        }}
        data-ad-client="ca-pub-7116765104034349"
        data-ad-slot="7465915067"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </AdContainer>
  );
};

export default DisplayAds;
